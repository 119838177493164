// Remove `no-js` class
document.documentElement.classList.remove('no-js')

// Add your codes here
$(document).ready(function () {
  $('.box-item').on('click tap', function () {
    var videoId = $(this).find('a').attr('data-videoid')

    var urlVideoIframe =
      'https://www.video.mediaset.it/player/playerIFrame.shtml?id=' +
      videoId +
      '&amp;hidesocial=true&amp;autoplay=true'

    var h =
      '<div class="video-overlay"><div class="box-close">X</div><iframe src="' +
      urlVideoIframe +
      '" allowfullscreen="" frameborder="0" scrolling="no" id="playeriframe"></iframe></div>'

    $('body').append(h)
    $('.video-overlay .box-close').on('click', function () {
      $('.video-overlay').remove()
    })
  })
})
$(function () {
  var header = $('#nav')

  $(window).scroll(function () {
    var scroll = $(window).scrollTop()
    if (scroll >= 50) {
      header.addClass('scrolled')
    } else {
      header.removeClass('scrolled')
    }
  })
})
$(document).ready(function () {
  $('.navbar-toggler').click(function () {
    $('#nav').toggleClass('full-menu')
  })
})

$('#volume-desk').click(function () {
  if ($('video.desktop-on').prop('muted')) {
    $('video.desktop-on').prop('muted', false)
    $(this).toggleClass('unmute')
  } else {
    $('video.desktop-on').prop('muted', true)
    $(this).toggleClass('unmute')
  }
})
$('#volume-mob').click(function () {
  if ($('video.mobile-on').prop('muted')) {
    $('video.mobile-on').prop('muted', false)
    $(this).toggleClass('unmute')
  } else {
    $('video.mobile-on').prop('muted', true)
    $(this).toggleClass('unmute')
  }
})
$('.modaljs').on('hidden.bs.modal', function (e) {
  $('.modaljs iframe').attr('src', $('.modaljs iframe').attr('src'))
})
